@import "./../../theme/core.scss";

.root {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: #3335;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    width: 316px;
    background-color: $white;
    border-radius: 8px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .textContainer {
      width: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      .body {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;
      }
      .message {
        font-size: 14px;
        text-align: center;
      }
      .description {
        font-size: 14px;
        text-align: center;
        color: $low-opacity-text;
      }
    }
  }
}

.default {
  height: 45px;
  width: 100%;
  color: $poppy-dark-blue;
  background: $poppy-blue;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: $low-opacity-text;
    cursor: progress;
  }
}

.danger {
  height: 45px;
  width: 100%;
  color: $white;
  background: $poppy-red;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: $low-opacity-text;
    cursor: progress;
  }
}

.submit {
  height: 45px;
  width: 100%;
  color: $poppy-dark-blue;
  background: $poppy-primary;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    background-color: $low-opacity-text;
    cursor: progress;
  }
}
