@import "../../theme/core.scss";

.root {
  position: relative;
  width: 340px;
  height: 120px;
  top: 2px;
  canvas {
    border-radius: 0px 0px 16px 16px;
  }
}
