@import "./theme/core.scss";

#body {
  background: $background;
  height: 100vh;
  width: 100vw;
  @include tablet-or-less {
    width: 100vw;
    height: 100vw;
  }
}

.error-message {
  padding: 5px;
  color: #ff8585;
  border: 1px solid red;
  border-radius: 3px;
  margin-top: 8px;
}

.good-text {
  color: #7af26f;
}

.moderate-text {
  color: #eceb7c;
}

.unhealthy-sg-text {
  color: #eccb7c;
}

.unhealthy-text {
  color: #ff8585;
}

.very-unhealthy-text {
  color: #ff8585;
}

.hazardous-text {
  color: #f28fc7;
}
