@import "../../theme/core.scss";

.root {
  background-color: $white;
  width: 300px;
  padding: 17px;
  border-radius: 10px;
  margin-bottom: 4px;
  margin-top: 8px;
  .container {
    background-color: $background;
    padding: 16px;
    border-radius: 8px;
    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      width: 100%;
      margin-bottom: 16px;
      font-size: 12px;
      .title {
        font-size: 15px;
        margin-right: auto;
      }
    }
    .statusList {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
      .listItem {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        gap: 8px;
        font-size: 12px;
      }
    }
  }
  .onlineBubble {
    width: 8px;
    height: 8px;
    background: rgb(0, 210, 0);
    border-radius: 4px;
  }
  .offlineBubble {
    width: 8px;
    height: 8px;
    background: rgb(228, 0, 0);
    border-radius: 4px;
  }
}
