// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Roboto:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Alata:wght@400;500;600;700");

// @import 'nprogress/nprogress';

@import "abstracts/_functions";
@import "abstracts/_variables";
@import "abstracts/_mixins";

.typographyTitle1 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 140%;
}

.typographyTitle2 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
}

.typographyTitle3 {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 140%;
  text-align: center;
}

.typographyHeadline {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 130%;
}

.typographyBody {
  font-weight: 400;
  font-size: 1.0625rem;
  line-height: 130%;
}

.typographyMessage {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  text-align: center;
}

.typographyMessageHint {
  font-size: 0.875rem;
  line-height: 140%;
  text-align: center;
  color: $low-opacity-text;
}
