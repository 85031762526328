@import "../../theme/core.scss";

.progressContainer {
  background-color: $white;
  width: 300px;
  padding: 8px;
  padding-inline-start: 17px;
  padding-inline-end: 17px;
  padding-block-start: 8px;
  padding-block-end: 8px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    color: $low-opacity-text;
    margin-bottom: 8px;
    font-weight: 500;
  }

  .progressBars {
    width: 100%;
    display: flex;
    gap: 1.34px;
    @keyframes pulse {
      0%,
      100% {
        background-color: $low-opacity-text;
      }
      50% {
        background-color: $poppy-blue;
      }
    }
    .progressBar {
      min-width: 50px;
      height: 12px;
      border-radius: 8px;
      .progressBarFill {
        height: 10px;
        position: absolute;
        border-radius: 8px;
        max-width: 296px;
      }
      .progressBarBackground {
        width: 100%;
        height: 10px;
        display: flex;
        background-color: $low-opacity-text;
        border-radius: 8px;
      }
    }
    .progressBar1 {
      width: 25%;
      .progressBarFill1 {
        background-color: $poppy-blue;
      }
    }
    .progressBar2 {
      width: 25%;
      .progressBarFill2 {
        height: 10px;
        width: 74px;
        background-color: $poppy-blue;
      }
      .progressBarFillOn2 {
        width: 74px;
        animation: pulse 1s infinite;
      }
      .progressBarFillOff2 {
        width: 74px;
        background-color: $low-opacity-text;
      }
    }
    .progressBar3 {
      width: 25%;
      .progressBarFill3 {
        background-color: $poppy-blue;
      }
    }
    .progressBar4 {
      width: 25%;
      .progressBarFill4 {
        width: 74px;
        background-color: $poppy-blue;
      }
    }
  }
}
