@import "../../../theme/core.scss";

.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: $black;
  gap: 12px;
  margin-bottom: 1rem;
  margin-top: 1rem;

  .headerContainer {
    background-color: $white;
    width: 300px;
    padding: 17px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: $low-opacity-text;
    h2 {
      color: $black;
      font-size: 20px;
      margin-bottom: 0.5rem;
      width: 100%;
      text-align: center;
    }
    .locationInfo {
      font-weight: 600;
    }
  }

  .deviceOptions {
    background-color: $white;
    width: 300px;
    padding: 8px;
    padding-inline-start: 17px;
    padding-inline-end: 17px;
    padding-block-start: 16px;
    padding-block-end: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 500;

    @include tablet-or-less {
      justify-content: center;
    }
  }

  .progressContainer {
    background-color: $white;
    width: 300px;
    padding: 8px;
    padding-inline-start: 17px;
    padding-inline-end: 17px;
    padding-block-start: 8px;
    padding-block-end: 8px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .status {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
      color: $low-opacity-text;
      margin-bottom: 8px;
      font-weight: 500;
    }

    .progressBars {
      width: 100%;
      display: flex;
      gap: 1.34px;
      @keyframes pulse {
        0%,
        100% {
          background-color: $low-opacity-text;
        }
        50% {
          background-color: $poppy-blue;
        }
      }
      .progressBar {
        min-width: 50px;
        height: 12px;
        border-radius: 8px;
        .progressBarFill {
          height: 10px;
          position: absolute;
          border-radius: 8px;
          max-width: 296px;
        }
        .progressBarBackground {
          width: 100%;
          height: 10px;
          display: flex;
          background-color: $low-opacity-text;
          border-radius: 8px;
        }
      }
      .progressBar1 {
        width: 25%;
        .progressBarFill1 {
          background-color: $poppy-blue;
        }
      }
      .progressBar2 {
        width: 25%;
        .progressBarFill2 {
          height: 10px;
          width: 74px;
          background-color: $poppy-blue;
        }
        .progressBarFillOn2 {
          width: 74px;
          animation: pulse 1s infinite;
        }
        .progressBarFillOff2 {
          width: 74px;
          background-color: $low-opacity-text;
        }
      }
      .progressBar3 {
        width: 25%;
        .progressBarFill3 {
          background-color: $poppy-blue;
        }
      }
      .progressBar4 {
        width: 25%;
        .progressBarFill4 {
          width: 74px;
          background-color: $poppy-blue;
        }
      }
    }
  }

  .buttonContainer {
    background-color: $white;
    width: 300px;
    padding: 17px;
    border-radius: 10px;

    .startRapidTest {
      height: 45px;
      width: 100%;
      color: $poppy-dark-blue;
      background: $poppy-blue;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      font-family: $alata;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        color: $white;
        background-color: $low-opacity-text;
        cursor: progress;
      }
    }
    .stopRapidTest {
      height: 45px;
      width: 100%;
      color: $white;
      background: $poppy-red;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: $low-opacity-text;
        cursor: progress;
      }
    }
  }

  .onGoingTestBox {
    background-color: $background;
    border-radius: 4px;
    padding: 12px;
    h3 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    ul {
      margin-left: 12px;
      list-style-type: circle;
      li {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
  }

  .interimResultContainer {
    background-color: $white;
    width: 300px;
    padding: 17px 17px 0px 17px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      height: 25px;
      font-size: 20px;
      font-weight: 500;
      align-self: flex-start;
      text-transform: capitalize;
    }

    .eachContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding-bottom: 1rem;
      padding-top: 1rem;
      border-bottom: 1px solid $poppy-line;

      .label {
        margin-bottom: 0.75rem;
        font-size: 16px;
        font-weight: 600;
      }
      .value {
        font-size: 28px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 8px;
      }
      .subLabel {
        margin-top: 0.25rem;
        font-size: 12px;
        font-weight: bold;
        color: #555;
        margin-bottom: 5px;
      }
    }

    .measurementsContainer {
      width: 100%;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 1rem 0 1.75rem 0;

      .mainValueLabel {
        width: 100%;
        margin-bottom: 1.5rem;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        line-height: 1.5rem;
      }
      .measurement {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .value {
          font-size: 18px;
          font-weight: bold;
          b {
            font-size: 24px;
            font-weight: 600;
          }
        }

        .label {
          margin-top: 8px;
          font-size: 12px;
          font-weight: bold;
          color: #555;
          text-align: center;
        }
      }
    }

    .timeRemaining {
      margin-top: 32px;
      font-size: 20px;
      color: $low-opacity-text;
    }
  }

  .sensorMeasurementsContainer {
    background-color: $white;
    width: 300px;
    padding: 17px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .header {
      height: 25px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 2rem;
    }
    .columnsHeader {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 4px;
      padding-right: 8px;
      border-bottom: 1px solid $poppy-line;
      gap: 36px;
      .columnLabel {
        font-size: 14px;
        font-weight: 500;
        margin-right: 0.5rem;
        &:first-child {
          margin-right: auto;
        }
      }
    }
    .sensors {
      display: flex;
      flex-direction: column;
      .measurements {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-block: 12px;
        padding-inline-end: 8px;
        font-size: 14px;
        &:not(:last-child) {
          border-bottom: 1px solid $lower-opacity-text;
        }
        p {
          min-width: 1.75rem;
          border-radius: 0.25rem;
          &:first-child {
            margin-right: auto;
          }
          &:last-child {
            padding: 0.25rem 1rem;
            text-align: center;
          }
        }
        .lowest {
          background-color: $poppy-highlight;
        }
      }
    }
  }
  .testResult {
    width: 100%;
    border-top: 1px solid $poppy-line;
    margin: 0.5rem 1rem;
    .testResultBackground {
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 1.75rem 0 1.75rem 0;
      width: 100%;
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .result {
        font-size: 40px;
        font-style: normal;
        font-weight: bold;
        line-height: 54px;
      }
      .value {
        font-size: 12px;
        color: #555;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
      }
      .standard {
        margin-top: 1rem;
        font-size: 12px;
        font-weight: bold;
        color: #555;
        text-align: center;
      }
    }
  }
  .testPassed {
    width: 100%;
    .testResultBackground {
      background-color: $poppy-green-transparent;
      .checkCircle {
        margin-top: 0.25rem;
        width: 85px;
        height: 85px;
        color: $poppy-green;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        svg {
          width: 85px;
          height: 85px;
          fill: $white;
        }
      }
    }
  }
  .testFailed {
    width: 100%;
    .testResultBackground {
      background-color: $poppy-orange-transparent;
      .failIconContainer {
        margin-top: 0.25rem;
        margin-left: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .triangle {
          position: absolute;
          width: 60px;
          height: 60px;
          color: $poppy-orange;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.5625rem;
          svg {
            width: 60px;
            height: 60px;
            fill: $white;
          }
        }
        .triangleOutline {
          position: absolute;
          width: 94px;
          height: 94px;
          color: $poppy-orange;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 94px;
            height: 94px;
            fill: $white;
          }
        }
      }
    }
  }
  .differentialContainer {
    border-top: 1px solid $poppy-line;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .header {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.5rem;
    }
    .value {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .label {
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.375rem;
        width: 200px;
      }
    }
    .passesForOccupants {
      background: #b7e3ca;
      width: 105%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
      padding: 0.5rem;
      position: relative;
      b {
        font-size: 1rem;
        font-weight: bold;
        color: #555;
        text-align: center;
      }
    }
  }
  .recentResultsContainer {
    background-color: $white;
    width: 300px;
    padding: 17px 17px 0px 17px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .header {
      height: 25px;
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .eventList {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 335px;
      overflow-y: scroll;

      .eventItem {
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $lower-opacity-text;

        &:last-child {
          border-bottom: none;
        }

        .name {
          font-size: 14px;
        }

        .value {
          font-size: 14px;
          font-weight: 600;
          gap: 4px;
          display: flex;
          align-items: center;
          margin-left: auto;
          flex-wrap: nowrap;
          .label {
            font-size: 12px;
            font-weight: 500;
          }
        }

        .time {
          margin-left: auto;
          font-size: 12px;
          font-weight: $low-opacity-text;
          text-align: right;
          white-space: nowrap;
        }
      }
    }
  }

  .notesContainer {
    background-color: $white;
    width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    padding: 17px;
    .header {
      margin-bottom: 0.5rem;
    }
    .inputGroup {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
    }
    .submitNotes {
      height: 45px;
      width: 35%;
      color: $poppy-dark-blue;
      background: $poppy-blue;
      border: none;
      border-radius: 10px;
      margin-top: 10px;
      margin-left: auto;
      font-size: 16px;
      font-weight: 500;
      font-family: $alata;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: $low-opacity-text;
        color: $white;
        cursor: default;
      }
    }
  }
}
