@import "../../theme/core.scss";

.root {
  background-color: $white;
  width: 300px;
  padding: 17px 17px 0px 17px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    height: 25px;
    font-size: 20px;
    font-weight: 500;
    align-self: flex-start;
    text-transform: capitalize;
  }

  .eachContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid $poppy-line;

    .label {
      margin-bottom: 0.75rem;
      font-size: 16px;
      font-weight: 600;
    }
    .value {
      font-size: 28px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 8px;
    }
    .subLabel {
      margin-top: 0.25rem;
      font-size: 12px;
      font-weight: bold;
      color: #555;
      margin-bottom: 5px;
    }
  }

  .demoSampleText {
    font-size: 68px;
    font-weight: 500;
    position: absolute;
    font-family: $alata;
    transform: rotate(-15deg) translateY(0rem);
    opacity: 25%;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  }

  .measurementsContainer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 1rem 0 1.75rem 0;

    .mainValueLabel {
      width: 100%;
      margin-bottom: 1.5rem;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      line-height: 1.5rem;
    }
    .measurement {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .value {
        font-size: 18px;
        font-weight: bold;
        b {
          font-size: 24px;
          font-weight: 600;
        }
      }

      .label {
        margin-top: 8px;
        font-size: 12px;
        font-weight: bold;
        color: #555;
        text-align: center;
      }
    }
  }

  .timeRemaining {
    margin-top: 32px;
    font-size: 20px;
    color: $low-opacity-text;
  }

  .testResult {
    width: 100%;
    border-top: 1px solid $poppy-line;
    margin: 0.5rem 1rem;
    .testResultBackground {
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 1.75rem 0 1.75rem 0;
      width: 100%;
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .result {
        font-size: 40px;
        font-style: normal;
        font-weight: bold;
        line-height: 54px;
      }
      .value {
        font-size: 12px;
        color: #555;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
      }
      .standard {
        margin-top: 1rem;
        font-size: 12px;
        font-weight: bold;
        color: #555;
        text-align: center;
      }
    }
  }
  .testPassed {
    width: 100%;
    .testResultBackground {
      background-color: $poppy-green-transparent;
      .checkCircle {
        margin-top: 0.25rem;
        width: 85px;
        height: 85px;
        color: $poppy-green;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        svg {
          width: 85px;
          height: 85px;
          fill: $white;
        }
      }
    }
  }
  .testFailed {
    width: 100%;
    .testResultBackground {
      background-color: $poppy-orange-transparent;
      .failIconContainer {
        margin-top: 0.25rem;
        margin-left: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .triangle {
          position: absolute;
          width: 60px;
          height: 60px;
          color: $poppy-orange;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.5625rem;
          svg {
            width: 60px;
            height: 60px;
            fill: $white;
          }
        }
        .triangleOutline {
          position: absolute;
          width: 94px;
          height: 94px;
          color: $poppy-orange;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 94px;
            height: 94px;
            fill: $white;
          }
        }
      }
    }
  }
  .differentialContainer {
    border-top: 1px solid $poppy-line;
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .header {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 0.5rem;
    }
    .value {
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .label {
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.375rem;
        width: 200px;
      }
    }
    .passesForOccupants {
      background: #b7e3ca;
      width: 105%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
      padding: 0.5rem;
      position: relative;
      b {
        font-size: 1rem;
        font-weight: bold;
        color: #555;
        text-align: center;
      }
    }
  }
}
