@import "../../theme/core.scss";

.root {
  width: 100%;

  .inputLabel {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 6px;
    white-space: nowrap;
    b {
      font-weight: 500;
      color: $low-opacity-text;
      margin-left: 0.25rem;
    }
  }

  .inputHint {
    font-weight: 400;
    font-size: 11px;
    color: $low-opacity-text;
    margin: rem(1);
    margin-bottom: 8px;
    font-style: italic;
  }

  .input {
    height: 40px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    font-size: 14px;
    padding-left: 20px;
    box-sizing: border-box;
    background: $background;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    &::placeholder {
      text-overflow: ellipsis !important;
      font-size: 12px;
    }
    &:not(:required) {
      background: $white;
    }
    &:required {
      border: 1px solid $red;
      outline-color: $red;
    }
  }
  textarea {
    font-family: inherit;
    font-size: inherit;
    height: 120px !important;
  }

  .checkbox {
    height: 20px;
    width: 20px;
    background-color: #eaeaea;
    border: 1px solid $lower-opacity-text;
    border-radius: 50%;
  }
  .checkboxActive {
    height: 20px;
    width: 20px;
    background-color: $primary !important;
    border: 1px solid $lower-opacity-text;
    border-radius: 50%;
  }

  .requiredCheckbox {
    border: 1px solid $red;
  }

  .formRadio {
    height: 20px;
    width: 20px;
    border: 4px solid $lower-opacity-text;
  }

  .errorMessage {
    margin-top: 4px;
    font-size: 10px;
    color: $red;
  }
}
