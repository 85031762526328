@import "../../../theme/core.scss";

.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: $black;
  gap: 12px;
  padding-block: 1rem;

  .buttonContainer {
    background-color: $white;
    width: 300px;
    padding: 17px;
    border-radius: 10px;

    .testActionButton {
      height: 45px;
      width: 100%;
      color: $poppy-dark-blue;
      background: $poppy-blue;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      font-family: $alata;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        color: $white;
        background-color: $low-opacity-text;
        cursor: progress;
      }
    }
    .stopRapidTest {
      height: 45px;
      width: 100%;
      color: $white;
      background: $poppy-red;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: $low-opacity-text;
        cursor: progress;
      }
    }
  }

  .sensorStatusContainer {
    background-color: $white;
    width: 300px;
    padding: 17px;
    border-radius: 10px;

    .sensorStatusBox {
      background-color: $background;
      padding: 16px;
      border-radius: 8px;
      .statusTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        width: 100%;
        margin-bottom: 16px;
        font-size: 12px;
        h3 {
          font-size: 15px;
          margin-right: auto;
        }
      }
      .statusList {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
        .listItem {
          display: flex;
          flex-direction: row;
          width: 100%;
          align-items: center;
          gap: 8px;
          font-size: 12px;
        }
      }
    }
  }

  .onGoingTestBox {
    background-color: $background;
    border-radius: 4px;
    padding: 12px;
    h3 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    ul {
      margin-left: 12px;
      list-style-type: circle;
      li {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
  }

  .deviceOnline {
    width: 8px;
    height: 8px;
    background: rgb(0, 210, 0);
    border-radius: 4px;
  }
  .deviceOffline {
    width: 8px;
    height: 8px;
    background: rgb(228, 0, 0);
    border-radius: 4px;
  }

  .interimResultContainer {
    background-color: $white;
    width: 300px;
    padding: 17px 17px 0px 17px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      height: 25px;
      font-size: 20px;
      font-weight: 500;
      // align-self: flex-start;
      text-transform: capitalize;
    }

    .mainValueContainer {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .mainValue {
        margin-left: 50px;
        font-size: 28px;
        font-weight: 600;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 8px;

        .mainValueLabel {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .label {
        margin-top: 8px;
        font-size: 12px;
        font-weight: bold;
        color: #555;
        margin-bottom: 5px;
      }
    }

    .measurementsContainer {
      width: 100%;
      display: flex;
      justify-content: space-around;

      &:last-child {
        margin-bottom: 24px;
      }

      .measurement {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .value {
          font-size: 18px;
          font-weight: bold;
          b {
            font-size: 24px;
            font-weight: 600;
          }
        }

        .label {
          margin-top: 8px;
          font-size: 12px;
          font-weight: bold;
          color: #555;
        }
      }
    }

    .timeRemaining {
      margin-top: 32px;
      font-size: 20px;
      color: $low-opacity-text;
    }
  }
}

.stopModalBackground {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  inset: 0;
  background-color: #3335;
  display: flex;
  align-items: center;
  justify-content: center;

  .stopModal {
    width: 316px;
    background-color: $white;
    border-radius: 8px;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .textContainer {
      width: 85%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      .title {
        font-size: 20px;
        text-align: center;
      }
      .message {
        font-size: 14px;
        text-align: center;
      }
      .description {
        font-size: 14px;
        text-align: center;
        color: $low-opacity-text;
      }
    }

    .cancelStop {
      height: 45px;
      width: 100%;
      color: $white;
      background: $primary;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: $low-opacity-text;
        cursor: progress;
      }
    }
    .confirmStop {
      height: 45px;
      width: 100%;
      color: $white;
      background: $red;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: $low-opacity-text;
        cursor: progress;
      }
    }
  }
}
