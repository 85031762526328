@import "./theme/core.scss";

.main {
  width: 100vw;
  height: 100%;
  display: grid;
  background-color: $background;
  grid-template-columns: 240px auto;
  grid-template-rows: 80px auto;
  grid-template-areas:
    "navBar mainBar"
    "navBar content";

  @include tablet-or-less {
    width: 100vw;
    grid-template-columns: 100%;
    grid-template-rows: 50px auto;
    grid-template-areas:
      "mainBar"
      // "navBar"
      "content";
  }
  .content {
    background-color: $background;
    grid-area: content;
    width: auto;
    height: fit-content;
  }
}
