@import "../../theme/core.scss";

.root {
  background-color: $white;
  width: 300px;
  padding: 17px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: $low-opacity-text;
  h2 {
    color: $black;
    font-size: 20px;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }
  .locationInfo {
    font-weight: 600;
  }
  .notFound {
    font-style: italic;
    scale: 0.8;
  }
}
