@import "../../theme/core.scss";

.root {
  background-color: $white;
  width: 300px;
  padding: 17px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .header {
    height: 25px;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
  .columnsHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 4px;
    padding-right: 8px;
    border-bottom: 1px solid $low-opacity-text;
    gap: 36px;
    .columnLabel {
      font-size: 14px;
      font-weight: 500;
      &:first-child {
        margin-right: auto;
      }
    }
  }
  .sensors {
    display: flex;
    flex-direction: column;
    .measurements {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-block: 8px;
      padding-inline-end: 4px;
      font-size: 14px;
      &:not(:last-child) {
        border-bottom: 1px solid $lower-opacity-text;
      }
      p {
        padding: 6px 8px;
        &:last-child {
          margin-left: 17px;
          text-align: right;
        }
        &:first-child {
          margin-right: auto;
          margin-left: 0;
          text-align: left;
          width: auto;
        }
      }
      .isMax {
        background-color: $lower-opacity-text;
        border-radius: 8px;
      }
    }
  }
}
