@import "../../../theme/core.scss";

.root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: $black;
  gap: 12px;
  margin-bottom: 1rem;

  .demoHeader {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    font-size: 12px;
    color: #1a7d9b;
    background-color: #e1f7fd;
    @include laptop-or-more {
      font-size: 16px;
      height: 36px;
    }
  }
  .demoHeaderBuffer {
    width: 100%;
    height: 30px;
  }

  .headerContainer {
    margin-top: 2.5rem;
    background-color: $white;
    width: 300px;
    padding: 17px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 500;
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: $low-opacity-text;
    h2 {
      color: $black;
      font-size: 20px;
      margin-bottom: 0.5rem;
      width: 100%;
      text-align: center;
    }
    .locationInfo {
      font-weight: 600;
    }
  }

  .deviceOptions {
    background-color: $white;
    width: 300px;
    padding: 8px;
    padding-inline-start: 17px;
    padding-inline-end: 17px;
    padding-block-start: 16px;
    padding-block-end: 16px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 500;

    @include tablet-or-less {
      justify-content: center;
    }
  }

  .buttonContainer {
    background-color: $white;
    width: 300px;
    padding: 17px;
    border-radius: 10px;

    .rapidTestActionButton {
      height: 45px;
      width: 100%;
      color: $poppy-dark-blue;
      background: $poppy-blue;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      font-family: $alata;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        color: $white;
        background-color: $low-opacity-text;
        cursor: progress;
      }
    }
    .stopRapidTest {
      height: 45px;
      width: 100%;
      color: $white;
      background: $poppy-red;
      border: none;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: $low-opacity-text;
        cursor: progress;
      }
    }
  }

  .onGoingTestBox {
    background-color: $background;
    border-radius: 4px;
    padding: 12px;
    h3 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 12px;
    }
    ul {
      margin-left: 12px;
      list-style-type: circle;
      li {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
  }

  .recentResultsContainer {
    background-color: $white;
    width: 300px;
    padding: 17px 17px 0px 17px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .header {
      height: 25px;
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    .eventList {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 335px;
      overflow-y: scroll;

      .eventItem {
        display: flex;
        align-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
        border-bottom: 1px solid $lower-opacity-text;

        &:last-child {
          border-bottom: none;
        }

        .name {
          font-size: 14px;
        }

        .value {
          font-size: 14px;
          font-weight: 600;
          gap: 4px;
          display: flex;
          align-items: center;
          margin-left: auto;
          flex-wrap: nowrap;
          .label {
            font-size: 12px;
            font-weight: 500;
          }
        }

        .time {
          margin-left: auto;
          font-size: 12px;
          font-weight: $low-opacity-text;
          text-align: right;
          white-space: nowrap;
        }
      }
    }
  }

  .demoModeDescription {
    background-color: $white;
    width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    padding: 17px;
    .textContainer {
      background-color: $background;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      padding: 17px 12px;
      .header {
        align-self: center;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
      .description {
        font-size: 0.75rem;
        font-weight: 400;
        white-space: pre-wrap;
        text-align: center;
      }
    }
  }

  .notesContainer {
    background-color: $white;
    width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    padding: 17px;
    .header {
      margin-bottom: 0.5rem;
    }
    .inputGroup {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
    }
    .submitNotes {
      height: 45px;
      width: 35%;
      color: $poppy-dark-blue;
      background: $poppy-blue;
      border: none;
      border-radius: 10px;
      margin-top: 10px;
      margin-left: auto;
      font-size: 16px;
      font-weight: 500;
      font-family: $alata;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        background-color: $low-opacity-text;
        color: $white;
        cursor: default;
      }
    }
  }
}
